<template>
  <!-- 系统管理员管理 -->
  <div>
    <!-- 顶部管理员搜索 跳转 -->
    <div class="flex">
      <div>
        <span>管理员姓名</span>
        <el-input size="medium" v-model="form" placeholder="请输入真实姓名"></el-input>
        <div>
          <el-button size="medium" @click="resetForm">重置</el-button>
          <el-button size="medium" type="primary">查询</el-button>
        </div>
      </div>
      <el-button size="medium" type="danger" @click="newpags">新增</el-button>
    </div>
    <!-- 中间表格区域 -->
    <el-table :data="data" border>
      <el-table-column prop="adminId" label="管理员ID"> </el-table-column>
      <el-table-column label="账号" prop="adminName"></el-table-column>
      <el-table-column label="真实姓名" prop="adminTureName"> </el-table-column>
      <el-table-column label="手机号" prop="adminTel"></el-table-column>
      <el-table-column label="最近登陆时间" prop="lastLoginTime"></el-table-column>
      <el-table-column label="备注" prop="adminDesc"></el-table-column>
      <el-table-column label="账户是否可用">
        <template slot-scope="scope">
          {{ !scope.row.store ? '启用' : '关闭' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" prop="time" width="">
        <template slot-scope="scope">
          <div class="operation">
            <div @click="onXiou(scope.row.adminId, scope.$index)">修改</div>
            <div @click="onClik(scope.row.adminId, scope.$index)">删除</div>
            <div @click="openLevel(scope.row)">二级密码</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 二级密码 -->
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center :before-close="closeLevel">
      <span>
        <div>
          <el-input v-model="levelPwd" placeholder="请输入二级密码"></el-input>
        </div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeLevel()">取 消</el-button>
        <el-button type="primary" @click="sendLevelPwd()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: 1,
      addadminName: '',
      form: '',
      data: [],
      rowInfo: '',
      total: null,
      centerDialogVisible: false,
      levelPwd: '',
      currentPage: 1,
      pageNumber: 10
    }
  },
  created() {
    this.adminList()
  },
  methods: {
    onClik(id, index) {
      let userId = JSON.parse(sessionStorage.getItem('admin')).adminId
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.axios.delete(`/admin/admin/delAdmin?id=${id}&userId=${userId}`, {}).then(res => {
            if (res.data.code == 200) {
              // 删除元素
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.adminList()
            } else {
              this.$message({
                type: 'errer',
                message: '删除失败!'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 二级密码
    openLevel(row) {
      console.log(row)
      this.rowInfo = row
      this.centerDialogVisible = true
    },
    // 确认二级密码
    sendLevelPwd() {
      console.log(this.rowInfo)
      if (this.levelPwd == '') {
        this.$message({
          type: 'info',
          message: '请输入二级密码'
        })
      } else {
        const that = this
        this.axios.get('/admin/admin/setTwoPwd', { id: this.rowInfo.adminId, pwd: this.levelPwd }).then(res => {
          console.log(res)
          that.rowInfo = ''
          that.adminList()
          that.centerDialogVisible = false
          that.levelPwd = ''
          this.$message({
            type: 'success',
            message: '二级密码设置成功!'
          })
        })
      }
    },
    // 关闭 弹出框
    closeLevel() {
      this.rowInfo = ''
      this.centerDialogVisible = false
      this.levelPwd = ''
    },
    // 获取管理员账号信息
    adminList() {
      this.axios.get(`/admin/admin/selectAdminList`, {}).then(res => {
        this.data = res.data.data.list
        this.total = res.data.data.total
        this.currentPage = res.data.data.current
        this.pageNumber = res.data.data.size
      })
    },
    //   重置
    resetForm() {
      this.form = ''
    },
    // 分页
    handleSizeChange(val) {
      this.pageNumber = val
      this.axios
        .get('/admin/admin/selectAdminList', {
          pageNumber: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          this.data = res.data.data.list
        })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.axios
        .get('/admin/admin/selectAdminList', {
          pageNumber: this.currentPage,
          pageSize: this.pageNumber
        })
        .then(res => {
          this.data = res.data.data.list
        })
    },
    // 点击跳转查看页面
    onXiou(adminId) {
      let Id = adminId
      this.$router.push({ path: 'addadministratorcc', query: { Id } })
    },
    // alert("新增管理员页面");
    newpags() {
      this.$router.push('addadministrator')
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    span {
      width: 163px;
    }
    div {
      margin: 0 10px;
    }
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
.operation {
  display: flex;
  div {
    margin-left: 12px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
</style>
